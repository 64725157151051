import { Icon, Typography, Tooltip } from "@material-ui/core";

import { fetchActions } from "app/store/actions/actionsUserSlice";
import { isCarrier } from "app/services/LoginService";
import {
	INVOICE_STATUSES_ICONS,
	INVOICE_STATUSES_OPTIONS,
	INVOICE_STATUSES_LABELS,
	INVOICE_STATUSES_SP_LABELS_ADMIN,
} from "app/main/consts/tripInvoiceConsts";
import { centsToDollars } from "app/main/utils/financeUtils";
import { formatCurrency } from "app/main/utils/tableUtils";

export const INVOICE_STATUS_FIELD = (readOnly = false) => {
	return {
		key: "status",
		type: "component",
		label: "Status",
		builder: (item) => {
			const disabled = readOnly || item.smartpay_eligible;
			const label = INVOICE_STATUSES_LABELS[item.status] ?? INVOICE_STATUSES_SP_LABELS_ADMIN[item.status];
			const icon = INVOICE_STATUSES_ICONS[item.status];
			if (disabled) {
				return (
					<div className="flex flex-row items-center align-center">
						{item?.smartpay?.ledgerTotals?.outstandingCents > 0 && (
							<Tooltip
								classes={{
									tooltip:
										"bg-white text-13 font-normal border-1 border-grey-400 " +
										(item.days_age > item.days_net_term ? " text-red " : " text-black  "),
								}}
								title={`This invoice can only be funded partially, $${centsToDollars(
									item?.smartpay?.ledgerTotals?.outstandingCents
								)} is still outstanding and will be paid when invoice is fully collected from the broker`}
							>
								<Icon className={"text-secondary text-22 ml:text-24"}>warning</Icon>
							</Tooltip>
						)}
						<Icon className={(icon?.color ?? "") + " text-22 ml:text-24"}>{icon?.name ?? "receipt"}</Icon>
						<Typography className={"ml-3 tracking-wide text-12 ml:text-13 " + (icon?.text ?? "")}>
							{label ?? item.status}
						</Typography>
					</div>
				);
			} else {
				return (
					<div className="flex-1 flex items-end h-full mb-24">
						<Icon className={(icon?.color ?? "") + " text-22 ml:text-24"}>{icon?.name ?? "receipt"}</Icon>
					</div>
				);
			}
		},
		edit: {
			urlPUT: "api/trip-invoicing/carriers/:carrierId/invoices/:invoiceId/status",
			triggerEvent: "tripsRevision",
			field: {
				key: "status",
				type: "select",
				label: "Status",
				options: INVOICE_STATUSES_OPTIONS,
				classes: { cell: "min-w-128", root: "mb-16 w-full", container: "border-" },
			},
			disabled: (item) => readOnly || item.smartpay_eligible,
			paramsBuilder: (item, dataIds) => {
				return {
					data: { status: item.status },
					dataIds: {
						carrierId: item.carrier ?? item.trip_data?.trip__view?.metadata?.carrier,
						invoiceId: item._id,
					},
				};
			},
			customDispatch: (item) => {
				if (isCarrier()) {
					return fetchActions({ carrierId: item?.carrier });
				}
				return null;
			},
		},
	};
};
export const INVOICE_CELL = {
	key: "invoice__view",
	type: "invoicing__view",
	label: "Invoice",
	viewConfig: {
		showSmartpayEligibility: true,
	},
};
export const TRIP_INVOICE_CELL = (showBroker) => ({
	key: "trip__view",
	label: "Trip",
	type: "trip__view",
	viewConfig: {
		showBroker,
	},
	table: { width: showBroker ? undefined : 200 },
});
export const BROKER = {
	key: "broker_data.broker_name",
	label: "Broker",
	type: "text",
	table: { minWidth: 180 },
};
export const DELIVERED_DATE = {
	key: "delivered_date",
	label: "Delivered On",
	type: "date",
	table: { minWidth: 150 },
};
export const AMOUNT = {
	key: "amount",
	label: ["Total", "Amount"],
	type: "currency",
	table: { align: "right", width: 100, sticky: true },
};

const tripInvoicingConf = ({ hasCarrierSmartpayEnabled, readOnly, showCarrier, multiaccountEnabled }) => {
	return {
		urlGET: `api/trip-invoicing/carriers/:carrierId/invoices`,
		idKey: "_id",
		listenEvent: ["tripsRevision", "invoiceRevision"],
		segmentKey: "TRIP_INVOICING",
		content: {
			table: {
				variant: "skinny",
				orderBy: [
					"broker_data.broker_name",
					"amount",
					"delivered_date",
					"invoiced_date",
					"factoring_date",
					"days_age",
					"submitted_date",
					"days_net_term",
				],
			},
			pagination: {
				enabled: true,
				resetScroll: true,
				defaut: {
					size: 20,
				},
			},
			order: {
				defaut: {
					key: "delivered_date",
					dir: "desc",
				},
			},
			export: {
				fileName: "Invoices",
			},
			filters: {
				search: true,
				items: [
					...(showCarrier
						? [
								{
									key: "carrier",
									type: "autocomplete",
									label: "Carrier",
									field: {
										noErrorMessage: true,
										classes: {},
									},
									autocomplete: {
										provider: "smarthop",
										url: "api/profile/carriers",
										preloadDefault: true,
										listenEvent: "profileRevision",
										params: {
											options_active_only: true,
											carrier_id: ":carrierId",
											filters: { invoicesStatus: "ENABLED" },
										},
									},
								},
						  ]
						: []),
					...(hasCarrierSmartpayEnabled
						? [
								{
									key: "smartpay_eligible",
									type: "select",
									label: "Factoring Type",
									defaultValue: "__NOT_SELECTED__",
									options: [
										{
											value: "__NOT_SELECTED__",
											label: "Not Selected",
										},
										{
											value: "__TRUE__",
											label: "SmartPay",
										},
										{
											value: "__FALSE_OR_EMPTY__",
											label: "No SmartPay",
										},
									],
								},
						  ]
						: []),
					{
						key: "status",
						type: "select",
						label: "Status",
						defaultValue: "__NOT_SELECTED__",
						options: [
							{
								value: "__NOT_SELECTED__",
								label: "Not Selected",
							},
							{
								value: "PENDING",
								label: "Pending",
							},
							{
								value: "GENERATED",
								label: "Generated",
							},
							{
								value: "__ALL_SUBMITTED__",
								label: "Submitted",
							},
							...(hasCarrierSmartpayEnabled
								? [
										{
											value: "SP_IN_REVIEW",
											label: "In Review",
										},
										{
											value: "SP_APPROVED",
											label: "Approved",
										},
								  ]
								: []),
							{
								value: "__ALL_PAID__",
								label: "Paid" + (hasCarrierSmartpayEnabled ? " | Funded" : ""),
							},
							{
								value: "CANCELED",
								label: "Canceled",
							},
							{
								value: "__PAST_DUE__",
								label: "Past Due",
							},
							...(hasCarrierSmartpayEnabled
								? [
										{
											value: "SP_CHANGE_REQUESTED",
											label: "Changes Requested",
										},
										{
											value: "SP_REJECTED",
											label: "Rejected",
										},
								  ]
								: []),
						],
					},
					{
						key: "createdAt",
						type: "dateRange",
						label: "Invoice Creation Date",
						shortcutPriority: "long",
						description: "Filter by creation date",
					},
					...(hasCarrierSmartpayEnabled
						? [
								{
									key: "outstandingAmountSign",
									type: "select",
									label: "Outstanding Amount",
									defaultValue: "__NOT_SELECTED__",
									options: [
										{
											value: "__NOT_SELECTED__",
											label: "All",
										},
										{
											value: "true",
											label: "With Outstanding Amount",
										},
									],
								},
						  ]
						: []),
				],
			},
			groups: {
				items: [
					{
						key: "deliveryDate",
						type: "checkbox",
						label: "Delivery Date",
					},
					{
						key: "generationDate",
						type: "checkbox",
						label: "Generation Date",
					},
					{
						key: "submittionDate",
						type: "checkbox",
						label: "Submittion Date",
					},
					{
						key: "requiredDocuments",
						type: "checkbox",
						label: "Required Documents",
					},
					...(hasCarrierSmartpayEnabled
						? [
								{
									key: "outstandingAmount",
									type: "checkbox",
									label: "Outstanding Amount",
								},
								{
									key: "fundAmount",
									type: "checkbox",
									label: "Fund Amount",
								},
						  ]
						: []),
				],
			},
			defaults: {
				initial: {
					filters: {
						status: "__NOT_SELECTED__",
					},
					groups: {
						submittionDate: true,
						deliveryDate: true,
					},
				},
				items: [
					{
						label: "All",
						filters: {
							status: "__NOT_SELECTED__",
						},
					},
					{
						label: "Pending",
						filters: {
							status: "PENDING",
						},
					},
					{
						label: "Generated",
						filters: {
							status: "GENERATED",
						},
					},
					{
						label: "Submitted",
						filters: { status: "__ALL_SUBMITTED__" },
					},
					{
						label: "Past Due",
						filters: { status: "__PAST_DUE__" },
					},
					{
						label: "Paid" + (hasCarrierSmartpayEnabled ? " | Funded" : ""),
						filters: { status: "__ALL_PAID__" },
					},
				],
			},
			// incompatible with inline editing unless we can find workaround
			click: {
				label: "Edit",
				grid: { variant: "menu" },
				dialogBuilder: (item) => ({
					viewId: "TRIP_INVOICE_VIEW",
					dataIds: { carrierId: item.carrier, tripId: item.trip_data.trip, invoiceId: item._id },
				}),
			},
			items: [
				INVOICE_STATUS_FIELD(readOnly),
				{ type: "separator" },
				{
					key: "invoice__view",
					type: "invoicing__view",
					label: "Invoice",
					viewConfig: {
						showSmartpayEligibility: true,
					},
					table: { width: 220 },
				},
				{ type: "separator" },
				...(showCarrier
					? [
							{
								key: "carrier__view.label",
								builder: (item) => item.carrier__view,
								type: "carrier__view",
								label: "Carrier",
								viewConfig: {
									readOnly,
									showMcNumber: true,
									showTestCarrier: true,
								},
								table: { width: 220 },
							},
					  ]
					: []),
				TRIP_INVOICE_CELL(showCarrier),
				...(showCarrier && !multiaccountEnabled ? [] : [BROKER]),
				{ type: "separator" },
				{
					key: "createdAt",
					label: "Created On",
					type: "date",
					table: { minWidth: 150 },
				},
				DELIVERED_DATE,
				{
					key: "generated_date",
					label: "Generated On",
					type: "date",
					group: "generationDate",
					table: { minWidth: 150 },
				},
				{
					key: "submitted_date",
					label: "Submitted On",
					type: "date",
					group: "submittionDate",
					table: { minWidth: 150 },
				},
				{ type: "separator" },
				{
					key: "days_net_term",
					label: ["Net", "Term"],
					table: { align: "right", width: 40 },
					type: "number",
				},
				{
					key: "days_age",
					label: "Age",
					type: "text",
					table: { align: "right", width: 80 },
					builder: (item) =>
						item.days_age === null ? null : ["PENDING", "GENERATED"].includes(item.status) ? null : item.days_age >=
						  0 ? (
							<Tooltip
								classes={{
									tooltip:
										"bg-white text-13 font-normal border-1 border-grey-400 " +
										(item.days_age > item.days_net_term ? " text-red " : " text-black  "),
								}}
								title={
									item.days_age > item.days_net_term
										? "Passed due date by " + (item.days_age - item.days_net_term)
										: item.days_net_term - item.days_age + " day(s) till due date"
								}
							>
								<div className="flex flex-row">
									{item.days_age > item.days_net_term && <Icon className="text-16 text-red mt-1">report_problem</Icon>}
									<Typography
										className={"text-12 ml:text-13 ml-3 " + (item.days_age > item.days_net_term ? " text-red " : "")}
									>
										{item.days_age}
									</Typography>
								</div>
							</Tooltip>
						) : null,
				},
				{ type: "separator", group: "requiredDocuments" },
				{
					key: "trip_data.rate_con_file__flag",
					type: "text",
					label: "RC",
					group: "requiredDocuments",
					builder: (item) =>
						item?.trip_data?.rate_con_file__flag ? (
							<Icon className="text-20">check</Icon>
						) : (
							<Icon className="text-20 text-grey">close</Icon>
						),
					table: { align: "center", width: 60 },
				},
				{
					key: "trip_data.pod_file__flag",
					type: "text",
					label: "BOL",
					group: "requiredDocuments",
					builder: (item) =>
						item?.trip_data?.pod_file__flag ? (
							<Icon className="text-20">check</Icon>
						) : (
							<Icon className="text-20 text-grey">close</Icon>
						),
					table: { align: "center", width: 60 },
				},
				{ type: "separator", table: { width: 16, sticky: true } },
				{
					key: "totalAdditions",
					type: "number",
					table: { align: "right", width: 120 },
					label: ["Total", "Additions"],
					builder: (item) => formatCurrency(item?.totalAdditions),
					export: {
						builder: (item) => formatCurrency(item?.totalAdditions),
					},
				},
				{
					key: "totalDeductions",
					type: "number",
					table: { align: "right", width: 120 },
					label: ["Total", "Deductions"],
					builder: (item) => formatCurrency(item?.totalDeductions),
					export: {
						builder: (item) => formatCurrency(item?.totalDeductions),
					},
				},
				{ type: "separator", table: { width: 16, sticky: true } },
				AMOUNT,
				...(hasCarrierSmartpayEnabled
					? [
							{
								key: "smartpay.ledgerTotals.outstandingCents",
								description:
									"Amount to be paid after invoice amount fully collected from broker (includes additions and deductions)",
								label: ["Outstanding", "Amount"],
								group: "outstandingAmount",
								type: "currency",
								builder: (item) =>
									item?.smartpay?.ledgerTotals?.outstandingCents > 0
										? centsToDollars(item?.smartpay?.ledgerTotals?.outstandingCents)
										: null,
								table: { align: "right", width: 80 },
							},
							{
								key: "smartpay_fund_amount",
								description: "Amount to be paid after invoice approved (excludes additions and deductions)",
								label: ["Fund", "Amount"],
								type: "currency",
								group: "fundAmount",
								table: { align: "right", width: 80 },
								builder: (item) =>
									item?.smartpay?.ledgerTotals?.immediateCents > 0
										? centsToDollars(item?.smartpay?.ledgerTotals?.immediateCents)
										: item?.smartpay?.amount_to_fund > 0
										? item?.smartpay?.amount_to_fund
										: null,
							},
					  ]
					: []),
			],
		},
	};
};

export default tripInvoicingConf;
